<template>
  <div>
    <div class="header-block mb-4">
      <h4 class="font-weight-bold mb-0">
        <i :class="titleIcon" />
        {{ title }}
        <span
          v-if="subTitle"
          class="text-muted font-weight-normal"
        >
          | {{ subTitle }}
        </span>
      </h4>
      <b-button
        v-if="buttonText"
        v-b-modal.create-entity
        variant="info"
        @click="$emit('onButtonClick')"
      >
        <i :class="`ion ion-${buttonIcon}`" />
        {{ buttonText }}
      </b-button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CpPageHeader',
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      default: '',
    },
    titleIcon: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    buttonIcon: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
  .header-block {
    display: flex;
    padding: 1rem 0;
    justify-content: space-between;
    align-items: center;

    h4 {
      margin: 0;
    }
  }
</style>
