<template>
  <div>
    <cp-page-header
      :title="$t('transferAgentHead.title')"
      title-icon="fas fa-user-tie"
      :sub-title="$t('affiliateManagement.title')"
    />
    <cp-table
      ref="affiliateManagementTable"
      @onViewElem="openViewModal"
      @onDeleteElem="openDeleteModal"
    />
    <cp-delete-affiliate-modal
      ref="cpDeleteAffiliateModal"
      :title="title"
      :message="message"
      :ok-text="title"
      @onSubmit="submitDelete"
    />
    <cp-view-affiliate-modal
      ref="cpViewAffiliateModal"
      :table-data="getHolderAffiliateRecordsData"
      :title="currentItem.fullName"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CpPageHeader from '~/components/common/layouts/page-header';
import CpTable from './components/table';
import CpDeleteAffiliateModal from './components/cp-delete-affiliate-modal';
import CpViewAffiliateModal from './components/cp-view-affiliate-modal';

export default {
  name: 'AffiliateManagement',
  metaInfo: {
    title: 'Affiliate Management',
  },
  components: {
    CpPageHeader,
    CpTable,
    CpDeleteAffiliateModal,
    CpViewAffiliateModal,
  },
  data() {
    return {
      currentItem: {},
      title: '',
      message: '',
    };
  },
  computed: {
    ...mapGetters('affiliateManagement', ['getHolderAffiliateRecordsData']),
    urlParam() {
      return {
        issuerId: this.$route.params.idIssuer,
        tokenId: this.$route.params.tokenId,
        userId: this.currentItem.id,
      };
    },
  },
  methods: {
    ...mapActions('affiliateManagement', ['getHolderAffiliateRecords', 'createHolderAffiliateRecords']),
    openDeleteModal(item) {
      this.currentItem = { ...item };
      this.title = item.affiliateStatus === 'no'
        ? this.$t('affiliateManagement.deleteModal.title.activate')
        : this.$t('affiliateManagement.deleteModal.title.deactivate');
      this.message = item.affiliateStatus === 'no'
          ? this.$t('affiliateManagement.deleteModal.message.activate')
          : this.$t('affiliateManagement.deleteModal.message.deactivate');
      this.$refs.cpDeleteAffiliateModal.$refs.modalLogic.show();
    },
    openViewModal(item) {
      this.currentItem = { ...item };
      this.getHolderAffiliateRecords(this.urlParam).then(() => {
        this.$refs.cpViewAffiliateModal.$refs.modalLogic.show();
      });
    },
    submitDelete(item) {
      const affiliateStatus = this.currentItem.affiliateStatus === 'yes' ? 'no' : 'yes';
      const status = { status: affiliateStatus };
      this.createHolderAffiliateRecords({
        ...this.urlParam,
        body: { ...item, ...status, tokenId: this.urlParam.tokenId },
      }).then(() => {
        this.$refs.affiliateManagementTable.$refs.cpTable.updateTableData();
        this.$refs.cpDeleteAffiliateModal.$refs.modalLogic.hide();
      });
    },
  },
};
</script>
