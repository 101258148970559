var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4"},[_c('cp-table',{ref:"cpTable",attrs:{"without-search":"","get-data-action":"affiliateManagement/getIssuerAffiliateHolders","url-params":_vm.urlParams,"fields":_vm.fields},scopedSlots:_vm._u([{key:"fullName",fn:function(ref){
var rowData = ref.rowData;
return [_c('router-link',{attrs:{"href":"#","to":_vm.getInvestorPath( rowData.item.id)}},[_vm._v(" "+_vm._s(rowData.item.fullName)+" ")])]}},{key:"affiliateStatus",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-badge',{attrs:{"title":rowData.item.affiliateStatus,"variant":_vm.affiliateStatusVisible(rowData).color}},[_vm._v(" "+_vm._s(_vm.affiliateStatusVisible(rowData).status)+" ")])]}},{key:"lastChange",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(_vm._f("dateFilter")(rowData.item.lastChange))+" ")]}},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],attrs:{"variant":"default action-button btn-xs md-btn-flat view-btn","title":"View"},on:{"click":function($event){return _vm.$emit('onViewElem', rowData.item)}}},[_c('i',{staticClass:"ion ion-md-eye"})]),_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],attrs:{"variant":"default action-button btn-xs md-btn-flat","disabled":_vm.isPendingStatus(rowData),"title":_vm.affiliateActivateTitle(rowData)},on:{"click":function($event){return _vm.$emit('onDeleteElem', rowData.item)}}},[_c('i',{staticClass:"ion ion-md-switch"})])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }