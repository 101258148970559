<template>
  <div>
    <cp-confirm-modal
      ref="modalLogic"
      body-class="p-0"
      ok-only
      scrollable
      size="md"
      :title="title"
      :footer-class="'justify-content-center'"
      @onOk="$emit('onOk')"
      @onCancel="$emit('onCancel')"
    >
      <template slot="slot-extended">
        <b-table
          striped
          class="card-table"
          :fields="getFields()"
          :items="tableData"
        >
          <template
            slot="createdAt"
            slot-scope="{ item }"
          >
            {{ item.createdAt | dateFilter }}
          </template>
          <template
            slot="status"
            slot-scope="{ item }"
          >
            <b-badge
              :title="item.status"
              :variant="affiliateColor(item.status)"
            >
              {{ item.status }}
            </b-badge>
          </template>
        </b-table>
      </template>
    </cp-confirm-modal>
  </div>
</template>
<script>
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';
import { viewAffiliateTable } from '../fields';
import { i18nKeyListConvert } from '~/utilities/i18n-util';

export default {
  name: 'CpViewAffiliateModal',
  components: {
    CpConfirmModal,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    okText: {
      type: String,
      default: '',
    },
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      viewAffiliateTable,
    };
  },
  methods: {
    show() {
      this.$refs.modalLogic.show();
    },
    hide() {
      this.$refs.modalLogic.hide();
    },
    getFields() {
      return i18nKeyListConvert(viewAffiliateTable, 'label');
    },
    affiliateColor(affiliateStatus) {
      if (affiliateStatus === 'no') return 'danger';
      if (affiliateStatus === 'pending') return 'warning';
      if (affiliateStatus === 'yes') return 'success';
    },
  },
};
</script>
<style scoped lang="scss">
  .view-btn {
    margin-right: 0.3rem;
  }
</style>
