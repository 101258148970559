<template>
  <div>
    <cp-general-modal
      ref="modalLogic"
      :title="title"
      :ok-text="okText"
      size="md"
      @shown="setInitial"
      @onOk="handleOk"
    >
      <cp-text-area
        v-model="model.comment"
        :rows="6"
        :label="$t('affiliateManagement.deleteModal.input.comment')"
        name="comment"
      />
      <cp-input
        v-model="model.documentId"
        :label="$t('affiliateManagement.deleteModal.input.documentId')"
        name="documentId"
      />
      <div class="row">
        <div class="col-12 d-md-flex">
          {{ message }}
        </div>
      </div>
    </cp-general-modal>
  </div>
</template>
<script>
import moment from 'moment';
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import { CpInput, CpTextArea } from '~/components/common/standalone-components/inputs';

export default {
  name: 'CpDeleteAffiliateModal',
  components: {
    CpInput,
    CpTextArea,
    CpGeneralModal,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    okText: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      model: {},
      initialState: {
        comment: '',
        documentId: `AM-${moment().format('YYYYMMDD')}-i`,
      },
    };
  },
  methods: {
    show() {
      this.$refs.modalLogic.show();
    },
    hide() {
      this.$refs.modalLogic.hide();
    },
    setInitial() {
      this.model = { ...this.initialState };
    },
    handleOk() {
      this.$emit('onSubmit', this.model);
    },
  },
};
</script>
