<template>
  <div class="mt-4">
    <cp-table
      ref="cpTable"
      without-search
      get-data-action="affiliateManagement/getIssuerAffiliateHolders"
      :url-params="urlParams"
      :fields="fields"
    >
      <template
        slot="fullName"
        slot-scope="{ rowData }"
      >
        <router-link
          href="#"
          :to="getInvestorPath( rowData.item.id)"
        >
          {{ rowData.item.fullName }}
        </router-link>
      </template>

      <template
        slot="affiliateStatus"
        slot-scope="{ rowData }"
      >
        <b-badge
          :title="rowData.item.affiliateStatus"
          :variant="affiliateStatusVisible(rowData).color"
        >
          {{ affiliateStatusVisible(rowData).status }}
        </b-badge>
      </template>
      <template
        slot="lastChange"
        slot-scope="{ rowData }"
      >
        {{ rowData.item.lastChange | dateFilter }}
      </template>
      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <b-btn
          v-b-tooltip.hover="true"
          variant="default action-button btn-xs md-btn-flat view-btn"
          title="View"
          @click="$emit('onViewElem', rowData.item)"
        >
          <i class="ion ion-md-eye" />
        </b-btn>
        <b-btn
          v-b-tooltip.hover="true"
          variant="default action-button btn-xs md-btn-flat"
          :disabled="isPendingStatus(rowData)"
          :title="affiliateActivateTitle(rowData)"
          @click="$emit('onDeleteElem', rowData.item)"
        >
          <i class="ion ion-md-switch" />
        </b-btn>
      </template>
    </cp-table>
  </div>
</template>

<script>
import { fields } from '../fields';
import routeNames from '~/utilities/routes';
import CpTable from '~/components/shared/cp-table';

export default {
  name: 'CpAffiliateManagementTable',
  components: {
    CpTable,
  },
  data() {
    return {
      fields,
    };
  },
  computed: {
    urlParams() {
      const { idIssuer: issuerId, tokenId } = this.$route.params;
      return { issuerId, tokenId };
    },
    affiliateStatusVisible() {
      return ({ item }) => ({
        status: item.affiliateStatus,
        color: this.affiliateColor(item.affiliateStatus),
      });
    },
  },
  methods: {
    getInvestorPath(userId) {
      const { idIssuer, tokenId } = this.$route.params;
      return routeNames.viewDetails(idIssuer, tokenId, userId, 'affiliateManagement');
    },
    affiliateActivateTitle(rowData) {
      if (rowData.item.affiliateStatus === 'no') return 'activate';
      if (rowData.item.affiliateStatus === 'yes') return 'deactivate';
      return '';
    },
    isPendingStatus(rowData) {
      return rowData.item.affiliateStatus === 'pending';
    },
    affiliateColor(affiliateStatus) {
      if (affiliateStatus === 'no') return 'danger';
      if (affiliateStatus === 'pending') return 'warning';
      if (affiliateStatus === 'yes') return 'success';
    },
  },
};
</script>
<style scoped lang="scss">
  .view-btn {
    margin-right: 0.3rem;
  }
</style>
