const tdClass = 'align-middle';
export const fields = [
  {
    key: 'fullName',
    i18nKey: 'affiliateManagement.label.table.fullName',
    tdClass,
  },
  {
    key: 'affiliateStatus',
    i18nKey: 'affiliateManagement.label.table.affiliateStatus',
    tdClass,
  },
  {
    key: 'lastChange',
    i18nKey: 'affiliateManagement.label.table.lastChange',
    tdClass,
  },
  {
    key: 'comment',
    i18nKey: 'affiliateManagement.label.table.comment',
    tdClass,
  },
  {
    key: 'actions',
    label: ' ',
    tdClass: 'text-nowrap align-middle text-center',
  },
];

export const viewAffiliateTable = [
  {
    key: 'createdAt',
    i18nKey: 'affiliateManagement.deleteModal.label.table.createdAt',
    tdClass,
  },
  {
    key: 'status',
    i18nKey: 'affiliateManagement.deleteModal.label.table.status',
    tdClass,
  },
  {
    key: 'comment',
    i18nKey: 'affiliateManagement.deleteModal.label.table.comment',
    tdClass,
  },
];
